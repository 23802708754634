import axios from 'axios';
import {
  requestInterceptor,
  errorResponseInterceptor,
} from '@/helpers/api/refreshTokenInterceptors';
import { getAuthHeader } from './headers';

export const legacySyncInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

legacySyncInstance.interceptors.request.use((config) => requestInterceptor(config));

legacySyncInstance.interceptors.response.use(
  (response) => response,
  (error) => errorResponseInterceptor(error)
);

const syncInstance = axios.create({
  baseURL: import.meta.env.VITE_SYNC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

syncInstance.interceptors.request.use((config) => requestInterceptor(config));

syncInstance.interceptors.response.use(
  (response) => response,
  (error) => errorResponseInterceptor(error)
);

/**
 * Sync Assets API
 */

export const uploadImagesToSyncAssetsAPI = async ({ siteId, images, storageType }) => {
  return await syncInstance.post(
    `assets/${siteId}/upload`,
    images.map(({ name, hash }) => ({ name, hash, storageType })),
    {
      headers: getAuthHeader(),
    }
  );
};

export const checkAssetsAsUploaded = ({ siteId, images }) => {
  return syncInstance.put(`assets/${siteId}/uploaded`, images, {
    headers: getAuthHeader(),
  });
};

export const uploadImagesToSyncS3Bucket = async ({ url, fields, image }) => {
  const headers = {
    Accept: '*/*',
    'Cache-Control': 'max-age=31536000, must-revalidate',
  };

  const formData = new FormData();

  Object.entries(fields).forEach(([key, value]) => formData.append(key, value));
  formData.append('file', image.blob, image.name);

  return await fetch(url, {
    headers,
    method: 'POST',
    body: formData,
    keepalive: false,
  });
};

export const createSyncData = async ({ site_id, payload }) => {
  return await legacySyncInstance.post(`sync/${site_id}`, payload, { headers: getAuthHeader() });
};
