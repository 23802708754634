<template>
  <template v-if="layersSyncState !== 'success'">
    <button
      class="wf-main-button"
      @click="handleLayersButtonClick"
      :disabled="
        (layersSyncState === 'waiting' && !hasSelectedLayers) ||
        isLoading ||
        (layersSyncState === 'ready' && hasOnlyNonAutoLayoutFrames)
      "
    >
      <span v-if="!isLoading" v-text="buttonText"></span>
      <template v-else>
        <span class="small-loader"></span>
        <span v-text="setLoadingButtonText()"></span>
      </template>
    </button>
  </template>
  <template v-else>
    <button class="wf-main-button wf-main-button--secondary" @click="handleBackButtonClick">
      <WfIcon icon="back-arrow" />
      Back
    </button>
    <WfOpenInWebflowButton />
  </template>
</template>
<script>
import { log } from '@/helpers/logger';
import { errorHandling } from '@/helpers/errors';
import { handleEvent, dispatch } from '@/figma/ui-message-handler';
import { mapState, mapActions, mapMutations } from 'vuex';
import { createExportEventObject } from '@/helpers/analytics';
import { trackEvent, FIGMA_EXPORT_COMPLETED } from '@/helpers/analytics';

import WfOpenInWebflowButton from '../WfOpenInWebflowButton.vue';
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: { WfOpenInWebflowButton, WfIcon },
  data() {
    return {
      buttonText: 'Sync to Webflow',
    };
  },
  mounted() {
    this.setLayersSyncState('waiting');
    this.setButtonText();
  },
  watch: {
    hasSelectedLayers() {
      this.setButtonText();
    },
    layersSyncState() {
      this.setButtonText();
    },
    hasLayersSyncDetails(newVal) {
      if (!newVal && this.layersSyncState !== 'success' && !this.isLoading) {
        this.setButtonText();
      }
    },
  },
  computed: {
    ...mapState([
      'layersSyncState',
      'hasSelectedLayers',
      'hasLayersSyncDetails',
      'layersToSync',
      'warning',
      'isLoading',
      'layersSuccessSource',
      'selectedSiteId',
      'figmaFileName',
      'layersToSync',
    ]),
    hasOnlyNonAutoLayoutFrames() {
      return this.layersToSync.every(
        (layer) =>
          (layer.layerData.type === 'FRAME' && !layer.layerData.layoutMode) ||
          layer.layerData.type === 'GROUP'
      );
    },
  },
  methods: {
    ...mapActions(['createSyncData']),
    ...mapMutations([
      'setLayersSyncState',
      'setLayersToSync',
      'setHasLayersSyncDetails',
      'setIsLoading',
      'setLayersSuccessSource',
    ]),
    setButtonText() {
      switch (this.layersSyncState) {
        case 'waiting':
          this.buttonText = !this.hasSelectedLayers ? 'Sync to Webflow' : 'Continue';
          break;
        case 'ready':
          this.buttonText = 'Sync to Webflow';
          break;
        case 'success':
          this.buttonText = 'Back';
          break;
      }
    },
    setLoadingButtonText() {
      if (this.layersSuccessSource === 'copy') {
        return 'Copying...';
      }

      switch (this.layersSyncState) {
        case 'waiting':
          return 'Getting details...';
        case 'ready':
          return 'Preparing to sync...';
      }
    },
    handleLayersButtonClick() {
      switch (this.layersSyncState) {
        case 'waiting':
          this.hasSelectedLayers ? this.getLayersInfo() : '';
          break;
        case 'ready':
          this.syncLayersToWebflow();
          break;
        case 'success':
          this.backToWaiting();
          break;
      }
    },
    async getLayersInfo() {
      this.setIsLoading(true);
      dispatch('getSelectedComponents');
      const layers = await new Promise((resolve) =>
        handleEvent('setSelectedComponents', (value) => resolve(value))
      );
      this.setLayersInfo(layers);
    },
    setLayersInfo(value) {
      if (!value?.layers || !value.layers.length) {
        this.setIsLoading(false);
        return;
      }

      this.updateLayersSyncState('ready');
      this.setHasLayersSyncDetails(true);
      this.setLayersToSync(value.layers);
      this.setIsLoading(false);
    },
    async syncLayersToWebflow() {
      this.setIsLoading(true);
      this.setLayersSuccessSource('sync');
      await this.getLayersToSync();
    },
    async getLayersToSync() {
      dispatch('getComponents');
      const layers = await new Promise((resolve) =>
        handleEvent('setComponents', (value) => resolve(value))
      );
      this.syncLayers(layers);
    },
    async syncLayers(layers) {
      const startTime = performance.now();
      const siteId = this.selectedSiteId;
      let syncId;

      const syncedData = {
        ...layers.syncedData,
        figma_file_name: this.figmaFileName,
        source: 'layers',
      };

      try {
        const { data } = await this.createSyncData({ site_id: siteId, payload: syncedData });
        syncId = data.sync_id;
      } catch (error) {
        // We need to handle the error here and individually
        // to make sure the UI behaves as expected
        log(error);
        errorHandling(error);
        this.setIsLoading(false);
        return;
      }

      this.updateLayersSyncState('success');
      const eventObject = createExportEventObject('layers', syncedData, startTime, syncId);
      trackEvent(FIGMA_EXPORT_COMPLETED, eventObject);
      this.setIsLoading(false);
    },
    backToWaiting() {
      this.updateLayersSyncState('waiting');
      this.setHasLayersSyncDetails(false);
      this.setLayersToSync([]);
      this.setLayersSuccessSource(null);
    },
    handleBackButtonClick() {
      this.updateLayersSyncState('ready');
      this.setLayersSuccessSource(null);
    },

    // HELPERS
    updateLayersSyncState(state) {
      this.setLayersSyncState(state);
      this.setButtonText();
    },
  },
};
</script>
