import PQueue from 'p-queue';
import PRetry from 'p-retry';

import { log } from '@/helpers/logger';
import { errorHandling } from '@/helpers/errors';

/**
 * Create Blob
 */

const createBlob = ({ image }) => {
  const type = image.type === 'png' ? 'image/png' : 'image/svg+xml';

  return new Blob([new Uint8Array(image.bytes).buffer], { type });
};

/**
 * Create Images HashMap
 */

export const createImagesHashMap = ({ images, maxImageSize }) => {
  const imagesTuple = images.map((image) => {
    const blob = createBlob({ image });

    const imageData = {
      ...image,
      blob,
      size: blob.size,
      width: image.width,
      height: image.height,
    };

    return [image.hash, imageData];
  });

  const maxImageSizeInBytes = maxImageSize * 1024 * 1024;
  const hasImageLargerThanMaxSize = imagesTuple.some(
    ([_, image]) => image.size > maxImageSizeInBytes
  );

  if (hasImageLargerThanMaxSize) errorHandling({ status: 413, message: 'max_image_size' });

  return {
    imagesMap: new Map(imagesTuple),
    hasImageLargerThanMaxSize,
  };
};

export const createFigmaImagesHashMap = ({ images, imagesMap }) =>
  new Map(
    images.flatMap(({ hash, name }) => {
      const { figmaHashes, height, width, size, type, bytes, scale } = imagesMap.get(hash);

      const imageData = { hash, name, height, width, size, type, bytes, scale };

      return figmaHashes.map((id) => [id, imageData]);
    })
  );

/**
 * Sync Images with API
 */

export const syncImagesWithAPI = ({ images, syncAPI, siteId, storageType }) => {
  const syncAssets = async () => {
    const { payload } = await syncAPI({ siteId, images, storageType });

    if (!payload) throw new Error('Sync Assets API returned no payload');

    return { payload };
  };

  return PRetry(syncAssets, {
    retries: 3,
    factor: 2,
    randomize: true,
    onFailedAttempt: (error) => log(error),
  });
};

/**
 * Prepare S3 Bucket Images Upload
 */

export const prepareS3BucketImagesUpload = ({ payload, imagesMap, s3BucketAPI }) => {
  const s3Payload = payload
    .filter((data) => !!data.uploadDetails)
    .map((data) => ({
      ...data.uploadDetails,
      image: {
        name: data.name,
        blob: imagesMap.get(data.hash).blob,
      },
    }));

  const syncToS3Bucket = async (data) => {
    const response = await s3BucketAPI(data);

    if (!response || !response.ok)
      throw new Error('Failed to upload images', {
        cause: {
          response: response
            ? {
                url: response.url,
                ok: response.ok,
                name: data.image.name,
                key: data.fields?.key,
                redirected: response.redirected,
                status: response.status,
                statusText: response.statusText,
                body: await response.text(),
              }
            : null,
        },
      });

    return response;
  };

  return s3Payload.map(
    (data) => () =>
      PRetry(() => syncToS3Bucket(data), {
        retries: 3,
        factor: 2,
        randomize: true,
        onFailedAttempt: (error) => log(error),
      })
  );
};

/**
 * Enqueue S3 Bucket Images Upload
 */

export const enqueueS3BucketImagesUpload = async ({ uploadImagesFns, setImagesToUpload }) => {
  const imagesQueue = new PQueue({ concurrency: 5 });

  let completed = 0;
  imagesQueue.on('active', () => {
    setImagesToUpload({
      total: uploadImagesFns.length,
      completed: completed++,
    });
  });

  try {
    return [await imagesQueue.addAll(uploadImagesFns), null];
  } catch (error) {
    errorHandling(error);

    return [null, error];
  }
};
