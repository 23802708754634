<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import md5 from 'md5';

import { errorHandling } from '@/helpers/errors';

import { dispatch, handleEvent } from '../figma/ui-message-handler';

import {
  createImagesHashMap,
  createFigmaImagesHashMap,
  syncImagesWithAPI,
  prepareS3BucketImagesUpload,
  enqueueS3BucketImagesUpload,
} from '@/models/images/syncImages';

import { checkAssetsAsUploaded } from '@/api/sync-api';

export default {
  mounted() {
    handleEvent('getImageHash', (bytes) => this.getImageHashForSvg(bytes));
    handleEvent('uploadImages', async ({ images, eventId, storageType }) => {
      try {
        await this.uploadImages(images, eventId, storageType);
      } catch (error) {
        if ([401, 403].includes(error.status)) {
          this.handleUploadImagesError(eventId, true);
          return errorHandling(error);
        }

        this.handleUploadImagesError(eventId); // Notify sandbox about the error
        throw error;
      }
    });
    handleEvent('getArrayBufferFromImage', (imageUrl) => this.handleArrayBufferFromImage(imageUrl));
  },
  computed: {
    ...mapState(['user', 'selectedSiteId', 'maxImageSize', 'maxFiles', 'imagesToUpload']),
  },
  methods: {
    ...mapMutations(['setHasImagesToCopy', 'setIsLoading', 'setImagesToUpload']),
    ...mapActions(['uploadImagesToSyncAssetsAPI', 'uploadImagesToSyncS3Bucket']),

    async handleArrayBufferFromImage(imageUrl) {
      const request = new XMLHttpRequest();
      request.open('GET', imageUrl);
      request.responseType = 'arraybuffer';
      request.setRequestHeader('Content-Type', 'application/xml');

      request.onload = async () => {
        const arrayBufferView = await new Uint8Array(request.response);
        dispatch('setArrayBufferFromImage', arrayBufferView);
      };
      request.send();
    },
    validateImages(images) {
      if (!images || images.length < 1) {
        throw new Error('No images to upload');
      }

      // TODO: guarantee we validate this before the upload or handle the error properly to the user.
      if (images.length >= this.maxFiles) {
        const message = `You are copying more than ${this.maxFiles} images (including icons). Copy smaller sections, one at a time.`;
        this.setupPayloadTooLargeErrorMessage(message);
        throw new Error('Too many images');
      }

      this.setHasImagesToCopy(true);
      return images;
    },

    /**
     * Sync Assets API
     */

    handleUploadImagesError(eventId, suppressError) {
      dispatch(`uploadedImages-${eventId}`, { uploadedImages: null, suppressError });
    },

    async uploadImages(images, eventId, storageType) {
      this.validateImages(images);

      const { imagesMap, hasImageLargerThanMaxSize } = createImagesHashMap({
        images,
        maxImageSize: this.maxImageSize,
      });

      // TODO: guarantee we validate this before the upload or handle the error properly to the user.
      if (hasImageLargerThanMaxSize) {
        throw new Error('Image size too large');
      }

      const { payload } = await syncImagesWithAPI({
        images,
        syncAPI: this.uploadImagesToSyncAssetsAPI,
        siteId: this.selectedSiteId,
        storageType,
      });

      const uploadImagesFns = prepareS3BucketImagesUpload({
        payload,
        imagesMap,
        s3BucketAPI: this.uploadImagesToSyncS3Bucket,
      });

      const [_, error] = await enqueueS3BucketImagesUpload({
        uploadImagesFns,
        setImagesToUpload: this.setImagesToUpload,
      });

      if (error) {
        error.cause = {
          ...error.cause,
          images: images.map(({ bytes: _bytes, ...image }) => image),
          storageType,
        };

        throw error;
      }

      const uploadedImagesMap = createFigmaImagesHashMap({ images, imagesMap });
      const uploadedImages = Array.from(uploadedImagesMap.entries()).map(([hash, image]) => {
        let uploadedImage = {
          id: hash, // Figma hash
          ...image,
        };

        if (storageType === 'webflow') {
          const imagePayload = payload.find((item) => item.hash === hash);
          if (!imagePayload)
            throw new Error('Image payload not found', {
              cause: {
                hash,
                image,
                payload,
              },
            });

          uploadedImage = {
            ...uploadedImage,
            assetId: imagePayload.assetId,
            assetUrl: imagePayload.assetUrl,
          };
        }

        return uploadedImage;
      });

      if (storageType === 'webflow') {
        checkAssetsAsUploaded({
          siteId: this.selectedSiteId,
          images: uploadedImages.map((image) => ({
            hash: image.hash,
            storageType,
            hasFile: true,
          })),
        });
      }

      dispatch(`uploadedImages-${eventId}`, { uploadedImages });
      this.setHasImagesToCopy(false);
    },

    getImageHashForSvg(bytes) {
      const decode = this.decodeUint8Array(bytes);
      let svgString = decode.replace(/\n/g, '');

      // SVGs are being duplicated because the svg string is always different - specifically its IDs.
      // Solution: Parse the svg string and replace all IDs with incremental integers.
      const ids = svgString.matchAll(/id="(.*?)"/g);
      const idsArray = Array.from(ids, (x) => x[1]);
      idsArray.forEach((id, i) => (svgString = svgString.replaceAll(id, (i + 1).toString())));

      // Hash the SVG
      const hash = md5(svgString);
      dispatch('setImageHash', hash);
    },
    decodeUint8Array(bytes) {
      return new TextDecoder().decode(bytes);
    },
    setupPayloadTooLargeErrorMessage(message) {
      const error = {
        status: 413,
        message,
      };
      errorHandling(error);
      this.setIsLoading(false);
    },
  },
};
</script>
